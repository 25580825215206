<template>
    <div class="relative pb-5 mt-5 ml-3 mr-3 bg-white rounded-lg shadow-lg">
        <LoadingComponent :isLoading="loading" />
        <div class="content">
            <div class="top-content">
                <div class="title text-black-blod-18">Banner 資訊</div>
                <el-form ref="formRefDom" :model="inputs" :rules="rules">
                    <div class="inputs">
                        <div class="input">
                            <div class="label">Banner 名稱</div>
                            <el-form-item prop="title">
                                <el-input v-model="inputs.title" clearable>
                                </el-input>
                            </el-form-item>
                        </div>
                        <div class="input">
                            <div class="label">目標網址</div>
                            <el-form-item prop="target">
                                <el-input v-model="inputs.target" clearable>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>
            <div v-if="route.name !== 'edit_app_banner'" class="bottom-content">
                <div class="title text-black-blod-18">Banner 圖片</div>
                <div class="upload-area">
                    <div class="left">
                        <div class="label">
                            上傳尺寸限制：{{ imageLimit.desktop.width }}*{{
                                imageLimit.desktop.height
                            }}）
                        </div>
                        <el-upload
                            action="#"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="changeFiledDesktop"
                            accept=".jpg, .jpeg, .png"
                            list-type="picture"
                            :file-list="fileList1"
                        >
                            <button
                                v-if="!imgObj1.url"
                                class="orange-btn-outline-800 md:text-sm text-xs mr-1 w-[113px] h-[35px]"
                            >
                                上傳圖片
                            </button>
                            <div slot="file"></div>
                        </el-upload>
                        <div v-if="imgObj1.url" class="display-img">
                            <div class="error" @click="handleRemove()">
                                <i class="fas fa-times"></i>
                            </div>
                            <img :src="imgObj1.url" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="actions"
            :class="route.name === 'edit_app_banner' ? 'mt-5' : ''"
        >
            <button class="mr-5 black-btn-outline-lg text-md" @click="goBack">
                返回列表
            </button>
            <button
                v-permission="['create', 'update']"
                class="orange-btn-800-lg text-md"
                @click="onSubmit"
            >
                {{ route.params.id !== undefined ? "儲存變更" : "新增" }}
            </button>
        </div>
    </div>
</template>


<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// base64 轉 Blob
import { dataURLtoBlob } from "@/service/imageTransfer.js";
// handle api 錯誤訊息
import { handleErrorMessage } from "../components/errorMessage";
import { checkHaveSelectData } from "@/service/anyService";

// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {
  name: "SetBanners"
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const router = useRouter();
  const route = useRoute();

  // 預設 image 暫存物件
  const defaultImg = {
    url: "",
    file: null,
    base64: ""
  };
  const loading = ref(false);
  // 輸入匡狀態
  const inputs = ref({
    target: "",
    title: ""
  });
  // 表單驗證
  const rules = ref({
    title: [{
      required: true,
      message: "請輸入標題",
      trigger: "blur"
    }],
    target: [{
      required: true,
      message: "請輸入目標網址",
      trigger: "blur"
    }]
  });
  // 表單 dom
  const formRefDom = ref(null);
  // 畫面呈現 image 暫存物件
  const imgObj1 = ref({
    ...defaultImg
  }); // 桌機版圖片
  const fileList1 = ref([]);
  const imageLimit = ref({
    desktop: {
      width: [335, 335 * 2, 335 * 3],
      height: [107, 107 * 2, 107 * 3]
    }
  });

  // 刪除圖片
  function handleRemove() {
    imgObj1.value = {
      ...defaultImg
    };
    fileList1.value = [];
  }
  // 更新圖片
  function changeFiledDesktop(file, fileList) {
    if (file.size / (1024 * 1024) > 5) {
      fileList1.value = [];
      alert("檔案大小超出5mb");
      return;
    }
    fileList1.value = fileList.slice(-3);
    imgObj1.value.url = file.url;
    imgObj1.value.file = file.raw;
    const reader = new FileReader();
    // 讀取上傳檔案
    reader.readAsDataURL(file.raw);
    // 將上傳檔案轉為base64
    reader.onload = () => {
      imgObj1.value.base64 = reader.result;
      // 確認 圖片 size
      checkImageLimit(imgObj1.value.base64, imageLimit.value.desktop);
    };
  }
  // 確認圖片寬高
  function checkImageLimit(base64, limit) {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      if (!limit.height.includes(img.height) || !limit.width.includes(img.width)) {
        fileList1.value = [];
        imgObj1.value = {
          ...defaultImg
        };
        alert("圖片不符合寬高限制");
      }
    };
  }

  // 新增 API
  async function createBanner() {
    const {
      end,
      start,
      title,
      activated,
      target
    } = inputs.value;
    if (!imgObj1.value.url) {
      alert("圖片未正確填入");
      return;
    }
    loading.value = true;
    const result = new FormData();
    result.append("title", title);
    result.append("target", target);
    if (imgObj1.value.base64) {
      const blob = dataURLtoBlob(imgObj1.value.base64);
      result.append("image", blob);
    }
    try {
      await proxy.$api.CreateAppBannerApi(result);
      proxy.$message({
        type: "success",
        message: "新增成功"
      });
      goBack();
    } catch (err) {
      console.log(err);
      const errMessage = err.response.data.errors ? handleErrorMessage(err.response.data.errors) : "新增失敗";
      proxy.$message({
        type: "error",
        message: errMessage
      });
    } finally {
      loading.value = false;
    }
  }
  // 返回列表
  function goBack() {
    router.push({
      path: "/homepage_setting/app_banners"
    });
  }

  /**
   * 取得 banner
   */
  async function getData() {
    try {
      const {
        data
      } = await proxy.$api.GetAppBannerApi(route.params.id);
      inputs.value = {
        title: data.title,
        target: data.target
      };
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    }
  }

  /**
   * 更新
   */
  async function updateBanner() {
    checkHaveSelectData([inputs.value.title, inputs.value.target], ["title", "target"], inputs.value);
    console.log(inputs.value);
    try {
      await proxy.$api.UpdateAppBannerApi(route.params.id, inputs.value);
      proxy.$message({
        type: "success",
        message: "更新成功"
      });
      goBack();
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "更新資料失敗"
      });
    }
  }

  /**
   * 表單發送
   */
  async function onSubmit() {
    try {
      if (formRefDom.value === null) {
        console.log(formRefDom.value);
        return proxy.$message({
          type: "error",
          message: "表單驗證失敗"
        });
      }
      // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
      await formRefDom.value.validate();
      // 判斷是否為更新畫面
      if (route.name === "edit_app_banner") {
        await updateBanner();
      } else {
        await createBanner();
      }
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "表單驗證失敗"
      });
    }
  }
  onMounted(async () => {
    if (route.params.id !== undefined && route.name === "edit_app_banner") {
      await getData();
    }
  });
  return {
    route,
    loading,
    inputs,
    rules,
    formRefDom,
    imgObj1,
    fileList1,
    imageLimit,
    handleRemove,
    changeFiledDesktop,
    goBack,
    onSubmit
  };
};
__sfc_main.components = Object.assign({
  LoadingComponent
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
}
.content {
    .title {
        padding-left: 20px;
    }
    .top-content {
        padding: 30px 5% 0;
        .inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 20px 30px;
            border-bottom: 1px solid #757575;
            .input {
                width: 48%;
                padding: 10px 0px;
            }
        }
    }
    .bottom-content {
        padding: 30px 5%;
        .upload-area {
            display: flex;
            padding: 20px 20px 30px;
            .left {
                flex: 1;
                margin-right: 30px;
            }
            .right {
                flex: 1;
            }
            img {
                width: 100%;
            }
        }
    }
    .label {
        color: rgb(133, 130, 130);
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.display-img {
    margin-top: 15px;
    position: relative;
    padding: 30px;
    .error {
        width: 24px;
        height: 24px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
::v-deep .el-upload-list {
    display: none;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
